export default {
  data() {
    return {
      form: {
        editedWaste: {
          jwnetItem: "",
          cbaName: "",
          wasteName: "",
          remarks: "",
          isActive: true
        },
        selectedCba: {
          jwnetItem: "",
          cbaName: "",
        },
        wasteNameCreateList: [
          {
            cbaItemId: null,
            wasteName: "",
            remarks: "",
          },
        ],
      },
    };
  },
};
