export default {
  data() {
    return {
      validate: {
        editedWaste: {
          jwnetItemType: { isValid: true, errMsg: "" },
          cbaName: { isValid: true, errMsg: "" },
          wasteName: { isValid: true, errMsg: "" },
          remarks: { isValid: true, errMsg: "" },
        },
        selectedCba: {
          jwnetItemType: { isValid: true, errMsg: "" },
          name: { isValid: true, errMsg: "" },
        },
        wastes: [
          {
            name: { isValid: true, errMsg: "" },
          },
        ],
      },
      isValidForm: true,
    };
  },
  methods: {
    checkInput() {
      let isValidForm = true;
      const v = this.validate;
      v.wastes.map((waste) => {
        if (!waste.name.isValid) {
          waste.name.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
      });
      return isValidForm;
    },
    checkEdit() {
      let isValidForm = true;
      const v = this.validate;
      if (!v.editedWaste.wasteName.isValid) {
        v.editedWaste.wasteName.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      return isValidForm;
    },
  },
};
