import axios from "axios";
import {API_URL} from "@/const";

export default {
  methods: {
    getCbaItemsApi(id) {
      return axios
        .get(`${API_URL.WASTEITEM.CBAITEM}/${id}`)
        .then((res) => {
          this.form.selectedCba.jwnetItem = res.data.jwnetItemInfo.name;
          this.form.selectedCba.cbaName = res.data.cbaItemInfo.name;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getWasteItemsApi(id) {
      return axios
        .get(`${API_URL.WASTEITEM.WASTENAME}/${id}`)
        .then((res) => {
          this.form.editedWaste.jwnetItem = res.data.jwnetItemInfo.name;
          this.form.editedWaste.cbaName = res.data.cbaItemInfo.name;
          this.form.editedWaste.wasteName = res.data.wasteNameInfo.name;
          this.form.editedWaste.remarks = res.data.wasteNameInfo.remarks;
          this.from.editedWaste.isActive = res.data.isActive;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
